body {
  font-family: 'Roboto', sans-serif;
}


.header {
  margin-top: 20px;
}

.purple-button {
  background-color: #4661AD !important;
  border-radius: 40px !important;
  font-size: 12px !important;
  transition: all 0.5s ease 0s;
}

.purple-button:hover {
  background-color: #232C46 !important;
}

.greetings p {
  font-weight: bold;
}

.Typewriter {
    font-size: 40px;
}

.Typewriter__wrapper, .Typewriter__cursor {
  color: #787878;
}

.bg-purple {
  background-color: #4661AD;
  position: relative;
  height: 290px;
}

.bg-purple p {
  font-size: 20px;
}

.bg-purple p:first-child {
  font-size: 18px; 
  font-weight: 700;
}

.card {
  width: 320px;
  height: 600px;
}

.card img {
  width: 60px;
}

.card h3 {
  font-size: 20px;
  font-weight: bold;
}

.card h4 {
  font-size: 20px;
  font-weight: 300;
  color: #4661AD
}

.card h5 {
  font-size: 16px;
  font-weight: 300;
  color: #4661AD
}

.card p {
  color: #646262;
  font-size: 15px;
  line-height: 20px;  
}

.card p {
  margin-top: -10px;
}

.col-sm {
  padding-left: 0px;
  padding-right: 0px;
  width: 400px;
}

.bg-purple-container {
  position: relative;
  width: 100%
}

.bg-purple-container img {
  width: 100%;
  height: auto;
}

.contact-details, .project-details {
  position: absolute;
  top: 20%;
  color: white;
}

.contact-details h1 {
  font-size: 200%;
  font-weight: bold;
  line-height: 100px;
}

.project-details h4 {
  font-weight: bold;
}

.diagonal-box {
  color: #fff;
  width: 100%;
  background-color: #4661AD !important; 
}

.qoutes {
  font-size: x-large
}

.content {
  position: relative;
}

.icons {
  padding: 10px;
  margin: 20px;
}

.icon {
  font-size: 40px;
  color: white;
  padding: 10px;
  transition: color 0.2s
}

.icon:hover {
  color: #232C46;
}

footer p {
  color: #232C46;
}

.modal-header img {
  margin-left: 15px;
}

.modal-header .btn-close {
  margin: -0.5rem 1.5rem -0.5rem auto !important;
}

.modal-content  {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important; 
}

.modal-content h3 {
  color: #4661AD;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.modal-content label {
  color: #787878;
}

.modal-content .purple-button {
  padding: 10px;
  width: 100px;
}


.responseModal .modal-header {
  border-bottom: none;
  border-radius: 0px;
  padding: 35px 0px;
}

.responseModal .modal-body p {
  font-size: 18px;
  letter-spacing: 3px;
}

.green .modal-header {
  background-color: #47c9a2;
}

.red .modal-header {
  background-color: #FF6B6B;
}

.response-modal-icon {
  font-size: 40px;
  padding: 10px;
  transition: color 0.2s
}

.icon-green {
    color: #47c9a2;
}

.icon-red {
  color: #FF6B6B;
}

/*
******************************************************************
******************************************************************
*/

/*Small phones from 0 to 480px*/
@media only screen and (max-width: 480px) {
  
  body {
    font-size: 80% !important
  }

  .header img {
    width: 70px;
  }

  .greetings p {
    font-size: 80% !important;
  }

  .purple-button {
    width: 130px;
  }

  .Typewriter {
    font-size: 100%;
  }

  .bg-purple {
    background-image: linear-gradient(to bottom, #4661AD, #4661AD 45%,#ffffff 40%, #ffffff);
    height: 120%;
  }

  .bg-purple p {
    font-size: 100% !important; 
  }
  
  .bg-purple-container {
    height: 160px;
  }

  .bg-purple-container img {
    height: 160px;
  }

  .contact-details p {
     margin-bottom: 0;
  }

  .contact-details h1 {
    font-size: 12px;
    line-height: 0px;
  }

  .contact-details {
    visibility: hidden;
  }

  .project-details {
    top: 10%;
    left: 10%;
  }

  .project-details {
    font-size: 80% !important;
  }

  .diagonal-box img {
    width: 60px;
  }


  .qoutes {
    font-size: 120%;
  }

}

/* Small devices (landscape phones, 500px and up) */
@media (min-width: 500px) {
  
  body {
    font-size: 90% !important
  }

  .purple-button {
    width: 120px;
  }

  .Typewriter {
    font-size: 120%;
  }

  .bg-purple {
    background-image: linear-gradient(to bottom, #4661AD, #4661AD 50%,#ffffff 50%, #ffffff);
    height: 100%;
  }

  .bg-purple p {
    font-size: 105% !important; 
  }

  .intro-video-web {
    margin-left: 20px;
  }

  .card {
    width: 70%;
  }

  .card img {
    width: 40px;
  }
  
  .card h3 {
    font-size: 90%;
  }
  
  .card h4 {
    font-size: 110%;
  }
  
  .card h5 {
    font-size: 90%;
  }
  
  .card p {
    font-size: 90%;  
  }

  .contact-details {
    visibility: hidden;
  }

  .project-details {
    top: 10%;
    left: 20%;
    color: white;
  }

  .contact-details h1 {
    font-size: 100%;
    font-weight: bold;
    line-height: 100px;
  }

  .project-details {
    font-size: 90% !important;
  }

  .project-details h4 {
    font-size: 100%;
    font-weight: bold;
  }

  .project-details p {
    font-size: 80% !important;
  }

  .qoutes {
    font-size: 140%;
  }
  
  
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  

  .greetings p {
    font-size: 120% !important;
  }
  
  .bg-purple {
    background-image: linear-gradient(to bottom, #4661AD, #4661AD 50%,#4661AD 50%, #4661AD);
    height: 290px;
  }

  .Typewriter {
    font-size: 25px;
  }

  .bg-purple p {
    font-size: 15px;
  }

  .card {
    width: 100%;
  }

  .contact-details {
    visibility: visible;
  }

  .project-details {
    top: 10%;
    left: 50%;
    color: white;
  }

  
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  
  .bg-purple p {
    font-size: 100% !important; 
  }

  .contact-details, .project-details {
    top: 30%;
    color: white;
  }

  .contact-details h1 {
    font-size: 130%;
    font-weight: bold;
    line-height: 100px;
  }

  .project-details {
    font-size: 120% !important;
  }

  .project-details h4 {
    font-size: 90%;
    font-weight: bold;
  }

  .project-details p {
    font-size: 70% !important;
  }

  .qoutes {
    font-size: large;
  }

  .card {
    width: 100%;
    height: 100%;
  }

  .card img {
    width: 40px;
  }
  
  .card h3 {
    font-size: 120%
  }
  
  .card h4 {
    font-size: 120%;
  }
  
  .card h5 {
    font-size: 100%;
  }
  
  .card p {
    font-size: 100%;  
  }
  
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  

  .bg-purple p {
    font-size: 100% !important; 
  }

  .contact-details, .project-details {
    top: 30%;
    color: white;
  }

  .contact-details h1 {
    font-size: 200%;
    font-weight: bold;
    line-height: 100px;
  }

  .project-details {
    font-size: 150% !important;
  }

  .project-details h4 {
    font-size: 100%;
    font-weight: bold;
  }

  .project-details p {
    font-size: 80% !important;
  }

  .qoutes {
    font-size: x-large;
  }

  .card {
    width: 370px;
    height: 600px;
    /*filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 25))*/
  }
}